<template>
  <div>
    <el-card>
      <el-tag
        size="medium"
        style="
          margin-bottom: 50px;
          line-height: 30px;
          width: 90px;
          height: 30px;
          text-align: center;
          font-size: 14px;
        "
        >首页设置</el-tag
      >
      <el-form ref="form" :model="formnew" label-width="80px">
        <img src="../img/tip.png" alt="" class="img" />
        <el-form-item label="位置">
          <el-select v-model="formnew.type" placeholder="请选择" size="medium">
            <el-option label="大图" :value="0" />
            <el-option label="小图" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item label="类型">
          <el-select
            v-model="formnew.sourceType"
            placeholder="请选择"
            size="medium"
          >
          <el-option :label="item" :value="+index" v-for="(item, index) in advertisingType" :key="index" />
          </el-select>
        </el-form-item>
        <el-form-item label="来源" v-if="formnew.sourceType != 4&&formnew.sourceType!=6">
          <el-col :span="8">
            <el-input
              v-model="formnew.title"
              disabled
              placeholder="根据类型选择：文章/活动/报告"
            />
          </el-col>
          <el-button
            type="primary"
            class="btnAdd"
            style="margin-left: 20px"
            @click="select"
            >请选择</el-button
          >
        </el-form-item>
        <template v-if="formnew.sourceType == 4 ||  formnew.sourceType==6">
          <el-form-item label="跳转链接">
          <el-col :span="3" v-if="formnew.sourceType==6" style="margin-right: 10px;">
            <el-input v-model="formnew.sourceId" placeholder="输入小程序APPID" />
          </el-col>
           <el-col :span="3" v-if="formnew.sourceType==6" style="margin-right: 10px;">
            <el-input v-model="formnew.sourceGhId" placeholder="输入小程序原始ID" />
          </el-col>
            <el-col :span="5">
               <el-input
               v-if="formnew.sourceType==6"
                v-model="formnew.otherUrl"
                placeholder="输入小程序地址"
              />
              <el-input
                v-else
                v-model="formnew.otherUrl"
                placeholder="请输入跳转链接"
              />
            </el-col>
          </el-form-item>
          <el-form-item label="标题">
            <el-col :span="8">
              <el-input
                clearable
                v-model="formnew.title"
                placeholder="请输入标题"
              />
            </el-col>
          </el-form-item>
          <el-form-item label="描述">
            <el-col :span="8">
              <el-input
                type="textarea"
                placeholder="请输入描述"
                rows="6"
                v-model="formnew.desc"
              >
              </el-input>
            </el-col>
          </el-form-item>
        </template>

        <el-form-item label="图片">
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :data="action"
          >
            <img
              v-if="formnew.banner"
              :src="baseurl + yu + formnew.banner"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
            <div slot="tip" class="el-upload__tip waring">
              请上传比例16:9的图片，否则会影响前台显示 <br />
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="结束时间">
          <div-picker v-model="formnew.endTime"></div-picker>
        </el-form-item>
        <el-form-item label="显示终端">
          <div-checkbox v-model="formnew.showTerminal">
            <el-checkbox label="1">
              pc
            </el-checkbox>
            <el-checkbox label="2">
              H5
            </el-checkbox>
            <el-checkbox label="3">
              小程序
            </el-checkbox>
          </div-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button v-if="!this.id" type="primary" class="btnAdd" @click="sub"
            >提交</el-button
          >
          <el-button
            v-if="this.id"
            type="primary"
            class="btnAdd"
            @click="editsub"
            >修改</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>

    <el-dialog title="文章列表" :visible.sync="diokuai" width="70%">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
      >
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <el-table-column prop="id" width="50" label="编号" />
        <el-table-column prop="title" label="标题" width="180">
          <template slot-scope="ew">
            <p>{{ ew.row.title }}</p>
            <a
              v-if="ew.row.originLink"
              :href="ew.row.originLink"
              target="“_blank”"
              style="font-size: 12px"
              >(原文链接)</a
            >
          </template>
        </el-table-column>
        <el-table-column prop="categoryName" label="分类" width="180" />
        <el-table-column
          prop="createTime"
          label="发布时间"
          sortable
          width="180"
        />
        <el-table-column prop="author" label="作者" width="180" />
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="!id"
              type="text"
              icon="el-icon-info"
              size="mini"
              class="skyblue"
              @click="showEdit(scope.row)"
              >添加</el-button
            >
            <el-button
              v-if="id"
              type="text"
              icon="el-icon-info"
              size="mini"
              class="skyblue"
              @click="eadit(scope.row)"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page.sync="currentPage"
        :total="total"
        :page-size="pagesize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-dialog>
    <el-dialog title="活动列表" :visible.sync="diokuai_1" width="70%">
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        :default-sort="{ prop: 'id', order: 'descending' }"
        @sort-change="sortChange"
      >
        <el-table-column prop="id" width="50" label="编号" sortable="custom" />
        <el-table-column label="海报" width="180">
          <template slot-scope="ew">
            <img
              :src="baseurl + yu + ew.row.banner"
              alt=""
              style="width: 120px"
            />
          </template>
        </el-table-column>
        <el-table-column prop="title" label="分类" width="150">
          <template slot-scope="ew">
            {{ ew.row.acId | getacid }}
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题" width="150" />
        <el-table-column prop="regUrl" label="报名链接" width="180">
          <template slot-scope="ew">
            <a :href="ew.row.regUrl" target="“_blank”">{{ ew.row.regUrl }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="endTime" label="活动时间" width="180">
          <template slot-scope="scope">
            {{ scope.row.beginTime }}至 {{ scope.row.endTime }}
          </template>
        </el-table-column>

        <el-table-column prop="beginTime" label="操作">
          <template slot-scope="ew">
            <el-button
              v-if="!id"
              type="text"
              icon="el-icon-info"
              size="mini"
              class="skyblue"
              @click="showEdit(ew.row)"
              >添加</el-button
            >
            <el-button
              v-if="id"
              type="text"
              icon="el-icon-info"
              size="mini"
              class="skyblue"
              @click="eadit(ew.row)"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page.sync="currentPage"
        :total="total"
        :page-size="pagesize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-dialog>
    <el-dialog title="报告列表" :visible.sync="diokuai_2" width="70%">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <el-table-column prop="id" width="100" label="编号" sortable="custom" />
        <el-table-column prop="title" label="标题" width="180">
          <template slot-scope="ew">
            <p>{{ ew.row.title }}</p>
            <p><a :href="baseurl + yu + ew.row.pdfUrl">(原文链接)</a></p>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="content" label="内容"> </el-table-column> -->
        <el-table-column prop="areaCode" label="地区">
          <template slot-scope="scope">
            {{ scope.row.areaCode | getarea }}
          </template>
        </el-table-column>
        <el-table-column prop="businessId" label="行业">
          <template slot-scope="scope">
            {{ scope.row.businessId | getbu }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="createTime" label="时间"> </el-table-column> -->
        <el-table-column prop="source" label="来源" />
        <el-table-column prop="banner" label="配图">
          <template slot-scope="ew">
            <img
              :src="baseurl + yu + ew.row.banner"
              alt=""
              style="width: 150px"
            />
          </template>
        </el-table-column>
        <el-table-column prop="desc" label="描述" />
        <el-table-column prop="createTime" label="时间" />

        <el-table-column label="操作">
          <template slot-scope="ew">
            <el-button
              v-if="!id"
              type="text"
              icon="el-icon-info"
              size="mini"
              class="skyblue"
              @click="showEdit(ew.row)"
              >添加</el-button
            >
            <el-button
              v-if="id"
              type="text"
              icon="el-icon-info"
              size="mini"
              class="skyblue"
              @click="eadit(ew.row)"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page.sync="currentPage"
        :total="total"
        :page-size="pagesize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-dialog>

    <!-- <el-dialog title="报告修改" :visible.sync="dialogVisiblexui" width="50%">
      <el-form ref="form" :model="form_1" label-width="80px">
        <el-form-item label="标题">
          <el-input v-model="form_1.title"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="form_1.desc"></el-input>
        </el-form-item>
        <el-form-item label="来源">
          <el-input v-model="form_1.source"></el-input>
        </el-form-item>
        <el-form-item label="地区">
          <el-select v-model="form_1.areaCode" placeholder="请选择">
            <el-option
              v-for="item in area.reportArea"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查看方式">
          <el-select v-model="form_1.viewType" placeholder="请选择">
            <el-option label="查看" :value="1"> </el-option>
            <el-option label="在线下载" :value="2"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="行业">
          <el-select v-model="form_1.businessId" placeholder="请选择">
            <el-option
              v-for="item in area.reportBusiness"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="配图">
          <el-upload
            class="avatar-uploader"
            :action='this.$store.getters.imgUrl'
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess_1"
          >
            <img v-if="form_1.banner" :src="form_1.banner" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="内容"> -->
    <!-- <el-input v-model="form.content" type="textarea"></el-input> -->
    <!-- <quill-editor
            ref="myQuillEditor"
            v-model="form_1.content"
            :options="quillOption"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisiblexui = false
            form_1 = {}
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="addedit">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
let that
import $ from 'jquery'
import {advertisingType} from '@/utils/dictionary.js';

export default {
  filters: {
    getarea: function(value) {
      // console.log(that.area.reportArea,value);
      if (that.area.reportArea) {
        var reportArea = that.area.reportArea[value]
        return reportArea
      }
    },
    getbu(value) {
      if (that.area.reportBusiness) {
        var reportBusiness = that.area.reportBusiness[value]
        return reportBusiness
      }
    },
    getviewType(val) {
      if (val == 1) {
        return '查看'
      } else {
        return '在线下载'
      }
    },
    getstau(val) {
      if (val == 1) {
        return '已发布'
      } else {
        return '未发布'
      }
    },
    getacid(val) {
      var sd = that.publi[val]
      return sd
    }
  },
  data() {
    return {
      date: [],
      title: '',
      diokuai_1: false,
      year: '',
      publishStatus: '',
      reportArea: '',
      reportBusiness: '',
      order: '',
      btn: true,
      action: {
        action: 'system'
      },
      banner: '',
      tableData: [],
      dioaddkuai: false,
      currentPage: 1,
      pagesize: 5,
      diokuai_2: false,
      list: [],
      pai: [],
      paiform: {},
      value: '',
      dialogVisible: false,
      dialogVisiblexui: false,
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      total: 0,
      type: '',

      form: {
        title: '',
        source: '',
        picture: '',
        content: '',
        desc: '',
        areaCode: '',
        banner: '',
        viewType: '',
        businessId: ''
      },
      form_1: {
        id: '',
        title: '',
        source: '',
        picture: '',
        content: '',
        desc: '',
        areaCode: '',
        banner: '',
        viewType: '',
        businessId: ''
      },
      formnew: {
        banner: '',
        sourceType: 1,
        showTerminal: '1,2,3'
      },
      publi: [],
      diokuai: false,
      header: {
        Token: sessionStorage.getItem('token')
      },
      area: [],
      id: this.$route.query.id,
      advertisingType
    }
  },
  beforeCreate() {
    that = this
  },
  updated() {
    //   console.log(this.form);
  },
  //   创建
  created() {
    this.getfrom()
  },
  watch: {
   
  },
  methods: {
    getfrom() {
      if (this.id) {
        this.$http
          .get('/admin/SysConfig/getHomeBannerById?id=' + this.id)
          .then(({ data: res }) => {
            this.formnew = res.data
          })
      }
    },
    select() {
      // console.log(this.formnew)
      if (this.formnew.sourceType == 1) {
        this.diokuai = true
      } else if (this.formnew.sourceType == 2) {
        this.diokuai_1 = true
      } else {
        this.diokuai_2 = true
      }
      this.getlist()
    },
    showEdit(row) {
      console.log(row)
      this.formnew.sourceId = row.id
      this.formnew.orderNum = row.orderNum
      this.formnew.title = row.title
      if (row.picture) {
        this.formnew.banner = row.picture
      } else {
        this.formnew.banner = row.banner
      }

      console.log(this.formnew)
      this.diokuai = false
      this.diokuai_1 = false
      this.diokuai_2 = false
      // this.$http
      //   .post('/admin/SysConfig/homeBannerAdd', {})
      //   .then(({ data: res }) => {
      //     {
      //       if (res.errorCode == 200) {
      //         this.$message.success(res.message)
      //         this.$router.push('/home_banner')
      //       } else {
      //         this.$message.error(res.message)
      //       }
      //     }
      //   })
    },
    eadit(row) {
      this.formnew.sourceId = row.id
      this.formnew.orderNum = row.orderNum
      this.formnew.title = row.title
      if (row.picture) {
        this.formnew.banner = row.picture
      } else {
        this.formnew.banner = row.banner
      }
      console.log(this.formnew)
      this.diokuai = false
      this.diokuai_1 = false
      this.diokuai_2 = false
      console.log(this.formnew)
    },
    subimt() {
      this.currentPage = 1
      this.getlist()
    },
    sub() {
      this.$http
        .post('/admin/SysConfig/homeBannerAdd', this.formnew)
        .then(({ data: res }) => {
          {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.$router.push('/home_banner')
            }
          }
        })
    },
    editsub() {
      this.formnew.id = this.id
      this.$http
        .post('/admin/SysConfig/homeBannerEdit', this.formnew)
        .then(({ data: res }) => {
          {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.$router.push('/home_banner')
            }
          }
        })
    },
    sortChange(e) {
      // console.log(e);
      this.order = e.prop + ' ' + e.order
      console.log(this.order)
    },
    showinput(id) {
      console.log(id)
      this.pai[id].pai = true
      setTimeout(() => {
        $('.id input').focus()
      }, 20)
    },
    handleInputBlur(id, ids, orderNum) {
      this.paiform.id = ids
      this.paiform.orderNum = orderNum
      this.$http
        .post('/admin/Report/sort', this.paiform)
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
          }
        })
      this.pai[id].pai = false
    },
    addkuai() {
      this.addkuai = false
    },
    getea() {
      this.$http.get('/admin/ReportCategory/getList').then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.area = res.data
        }
      })
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },
    handleSizeChange(e) {
      this.pagesize = e
      console.log(e)
    },
    handleCurrentChange(e) {
      this.currentPage = e
      this.getlist()
    },
    getlist() {
      if (this.formnew.sourceType == 1) {
        this.$http
          .get(`/admin/Article/getList`, {
            params: {
              page: this.currentPage,
              pageSize: this.pagesize,
              publicDate: '',
              checkStatus: 1,
              publishStatus: 1,
              classification: '',
              type: this.type,
              sourceType: this.sourceType
            }
          })
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              console.log(res)
              this.list = res.data.list
              this.total = res.data.totalCount
              // this.getarea()
              this.list.forEach(item => {
                this.pai.push({ id: item.id, pai: false })
              })
              this.getea()
            }
          })
      } else if (this.formnew.sourceType == 2) {
        this.$http
          .get(`/admin/Activity/getList`, {
            params: {
              page: this.currentPage,
              pageSize: this.pagesize,
              eventDate: '',
              publiShdate: '',
              title: '',
              publishStatus: 1,
              checkStatus: 1,
              acId: '',
              order: ''
            }
          })
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.tableData = res.data.list
              this.total = res.data.totalCount
              this.tableData.forEach(item => {
                this.pai.push({ id: item.id, pai: false })
              })
              console.log(this.pai)
            }
          })
        this.$http
          .get('/admin/ActivityCategory/getList')
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.publi = res.data
            }
          })
      } else {
        this.$http
          .get('/admin/Report/getList', {
            params: {
              page: this.currentPage,
              pageSize: this.pagesize,
              date: '',
              title: '',
              year: '',
              publishStatus: 1,
              reportArea: '',
              reportBusiness: '',
              order: ''
            }
          })
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.list = res.data.list
              this.total = res.data.totalCount
              // this.getarea()
              this.list.forEach(item => {
                this.pai.push({ id: item.id, pai: false })
              })
              this.getea()
            }
          })
      }
    },

    handleAvatarSuccess(res, file) {
      if (res.errorCode == 200) {
        this.formnew.banner = res.data.url
      }
      console.log(file)
      //   console.log(imgurlbase)
    },
    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === 'image/jpeg'
    //   const isLt2M = file.size / 1024 / 1024 < 2

    //   if (!isJPG) {
    //     this.$message.error('上传头像图片只能是 JPG 格式!')
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!')
    //   }
    //   return isJPG && isLt2M
    // },
    handleAvatarSuccess_1(res, file) {
      if (res.errorCode == 200) {
        this.form_1.banner = res.data.url
      }
      console.log(file)
      //   console.log(imgurlbase)
    },
    beforeAvatarUpload_1(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    add() {
      if (this.btn) {
        this.$http
          .post('/admin/Report/add', this.form)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success('操作成功')
              this.dialogVisible = false
              location.reload()
              console.log(this.form)
            }
            this.btn = true
            // if(res.)
          })
      }
      this.btn = false
    },
    addedit() {
      if (this.btn) {
        this.$http
          .post('/admin/News/edit', this.form_1)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.dialogVisiblexui = false
              this.$message.success(res.message)
              this.getlist()
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    open(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/Report/del', { params: { id: id } })
            .then(res => {
              //   console.log(res)
              if (res.data.errorCode == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                if (this.total % 10 == 1) {
                  this.currentPage--
                }
                this.getlist()
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    show(id, el) {
      this.$http
        .post('/admin/Report/publish', { id: id, publishStatus: el })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlist()
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.out {
  // width: 500px;

  margin-bottom: 20px;
  .input {
    width: 37%;
    display: inline-block;
    margin-right: 30px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  // width: 178px;
  height: 178px;
  display: block;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.out .input {
  width: 18%;
}
.img {
  position: absolute;
  right: 199px;
  width: 600px;
}
.waring {
  color: #fda400;
  line-height: 24px;
}
.ml {
  margin-left: 10px;
}
</style>
